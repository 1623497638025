.detail-modal {
  padding: 2rem 0;
  .card {
    border: 1px solid #DDDDDD;
    padding: 1rem;
    margin: 1rem 0 2rem 0;
    border-radius: 4px;

    .section-header {
      margin-bottom: 1rem;
    }

    .section {
      padding: .5rem 0;
      .header {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 0;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-weight: 400;
        }
      }
    }
  }

  .qr-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    img {
      margin-bottom: 1rem;
    }
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 2rem 0;

    .arrow {
      height: 1.25rem;
      width: auto;
      cursor: pointer;
      
    }
    .arrow-left {
      transform: rotate(180deg);
      margin-right: 1.5rem;
    }
  }

  .error {
    // special case to not show errors as red on the final confirmation screen
    color: #333;
    font-weight: 400;
  }
}