.home-page {
  box-sizing: border-box;
  margin-bottom: 8rem;

  .header-section {
    margin-bottom: 1.5rem;
    .title {
      margin-bottom: 1rem;
    }
  }

  .appointment-card {
    margin-bottom: 1rem;
    .button-card {
      padding: 0.5rem 0 0 0;

      .button-row {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;

        * {
          width: 100%;
        }
        *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
