.confirm-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .card-section {
    padding: 0.5rem 0;
  }

  button {
    width: 100%;
  }

}