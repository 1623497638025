.registration-page {
  box-sizing: border-box;
  min-height: 100%;

  .zero-state-card {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 1rem;
    margin: 1.5rem 0;
    background-color: white;
    text-align: center;

    h6 {
      margin-top: 1.5rem;
    }
  }

  h2 {
    margin-bottom: 1rem;
  }

  .registration-wrapper {
    padding-bottom: 0;

    &.drawer-open {
      padding-bottom: 8rem;
    }
  }

  .progress-bar-wrapper {
    margin: 0.5rem 0 1rem;
    background-color: #ddd;
    height: 0.5rem;
    border-radius: 0.25rem;

    .inner {
      display: block;
      border-radius: 0.25rem;
      height: 8px;
      transition: width 300ms linear;
      position: relative;
      overflow: hidden;
      width: 0;
    }

    .animated {
      display: block;
      border-radius: 0.25rem;
      height: 8px;
      transition: width 300ms linear;
      position: relative;
      overflow: hidden;
      width: 0;
    }

    .animated:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.8) 100%
      );
      // animation: shimmer 1.5s ease-out infinite;
      content: "";
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(0%);
      opacity: 0;
    }
  }

  .card-wrapper {
    margin: 1.5rem 0;
    border-radius: 4px;

    .review-item-card {
      border-radius: 4px;
      padding: 1rem;
      background-color: white;

      .title-row {
        display: flex;
        align-items: center;
        margin-bottom: 0.75rem;

        img {
          margin-right: 0.75rem;
          max-width: 100%;
          height: 1.25rem;
          margin-top: -2px; //TODO: Make this flex box to align center
        }
      }

      .button-row {
        display: flex;
        flex-direction: row;
        margin: 1rem 0;

        button {
          flex: auto;

          &:first-child {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .more-details {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      > img {
        height: fit-content;
      }
    }

    .more-details-section {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      margin-top: 0.75rem;

      .field-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
    }
  }

  .card-wrapper.one {
    border: 1px solid #dddddd;
  }

  .card-wrapper.many {
    border: 1px solid #dddddd;
    .review-item-card {
      border-radius: 0;
    }
    .review-item-card:first-child {
      border-radius: 4px 4px 0 0;
    }
    .review-item-card:last-child {
      border-radius: 0 0 4px 4px;
    }
    .review-item-card:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
  }

  .progress-drawer {
    padding-bottom: 1rem;
    h5 {
      margin-bottom: 1rem;
      text-align: center;
    }
  }
}
