/* typography and fonts */

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Light.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: url("./assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Inter;
  --wbekit-font-smoothing: antialiased;
  --moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

html,
body,
#root,
.app {
  height: 100%;
  min-height: 100%;
  scrollbar-width: none;
}

a,
a:active,
a:hover,
a:hover,
a:target,
a:visited {
  text-decoration: none;
  outline: none;
}

button {
  cursor: pointer;
  outline: none;
  box-shadow: none !important;
}

input {
  padding: 0 0.5rem;
}

input:focus {
  outline: none !important;
}

.ReactModalPortal {
  width: 100vw;
}

.noscroll {
  overflow-y: hidden;
}
