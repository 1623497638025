// 2px - Used for some svgs & borders that are selected (set inside)
$spacing-0: 0.125rem;

// 4px - Unused
$spacing-10: 0.25rem;

// 8px - Small spacing between element & text
$spacing-20: 0.5rem;

// 12px - Common spacing for rows within a grouped component (Atoms)
$spacing-30: 0.75rem;

// 16px - Base size for all rem calculations
$spacing-40: 1rem;

// 20px - Used when you need 24px top or bottom total padding paired with text
$spacing-50: 1.25rem;

// 24px - Most common spacing for separate groups of components (Molecules)
$spacing-60: 1.5rem;

// 32px - Padding for top/bottom of containers in admin or sections (Organisms)
$spacing-70: 2rem;

// 40px - Common spacing for when you need to 2x $spacing-50
$spacing-80: 2.5rem;

// 48px - Space used for bottom of every page (all apps)
$spacing-90: 3rem;

// 64px - Largest gap available in the system
$spacing-100: 4rem;
