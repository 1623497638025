.insurance-modal {
  padding: 1.5rem 0 3rem;

  .insurance-card-wrapper {
    margin: 0.5rem 0 1.5rem;
  }

  .insurance-card {
    margin: 1rem 0;
  }

  .processing-card {
    background: #ffffff;
    /* shade40 */
    border: 1px solid #dddddd;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    margin: 1rem 0;
    min-height: 235px;

    .checkmark {
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .progress-bar-wrapper {
      margin: 1rem 0;
      background-color: #ddd;
      height: 0.5rem;
      border-radius: 0.25rem;
      width: 100%;

      .inner {
        display: block;
        border-radius: 0.25rem;
        height: 8px;
        animation: progress-bar-width 1.5s linear;
        animation-delay: 0s;
        width: 100%;
      }
    }

    @keyframes progress-bar-width {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }

  .camera-wrapper {
    text-align: center;
    // background-color: black;
    // color: white;
    p {
      margin: 4rem 0;
      // color: white;
    }

    button {
    }
  }

  .button-group {
    display: flex;
    flex-direction: column;
    button {
      width: auto;
      margin-bottom: 1rem;
    }
  }
}
