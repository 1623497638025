// scheduling modal content
.schedule-appt-modal {
  padding-bottom: 3rem;
  .cancel-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
  }

  .text-section {
    margin: 1.25rem 0;
  }

  .appt-row {
    .header {
      display: flex;
      flex-direction: row;
    }
    .appt-grid {
      padding: 0.75rem 0 1.25rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      button {
        margin: 0 0.25rem 0.25rem 0;
        min-width: 92px;
      }

      button:last-child {
        margin-right: 0;
      }
    }
  }
}
