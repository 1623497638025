.review-page {
  box-sizing: border-box;
// padding: calc(65px + 1rem) 1rem 1rem 1rem;  
  display: flex;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: 400px;
    line-height: 30px;
  }

  .body {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  button {
    width: 100%;
    margin-bottom: .5rem;
  }
}