/*
 * 320px -- smartphones, iPhone, portrait 480x320 phones
 * 481px -- portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide
 * 641px -- portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones
 * 961px -- tablet, landscape iPad, lo-res laptops ands desktops
 * 1025px -- big landscape tablets, laptops, and desktops
 * 1281px -- hi-res laptops and desktops
 *
 * https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile
 */
@mixin breakpoint($point, $min: true) {
  @if $point == phone {
    @media only screen and (if($min, min-width, max-width): 20em) { @content; }
  }
  @else if $point == tablet {
    @media only screen and (if($min, min-width, max-width): 60em) { @content; }
  }
  @else if $point == computer {
    @media only screen and (if($min, min-width, max-width): 80em) { @content; }
  }
}
