.recommendation-content {
  padding: 2rem 0 3rem;

  h4 {
    margin: 1rem 0 1.5rem;
  }

  p {
    margin: 0.5rem 0 1rem;
  }

  .button-group {
    margin-top: 1.5rem;

    button {
      margin-bottom: 1rem;
    }
  }
}
