.recommendations-modal-content {
  padding: 2rem 0 3rem 0;
  .header {
    margin-bottom: 1.5rem;
  }

  .card {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-bottom: 0.75rem;

    .header {
      padding: 1.25rem 1rem 0 1rem;
      margin-bottom: 0;
    }

    .subcard {
      padding: 1.5rem 1rem;
      text-align: left;
      .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 0.75rem;
          max-width: 100%;
          height: 1.25rem;
          margin-top: -2px; //TODO: Make this flex box to align center
        }
        padding: 0 0 1rem 0;
      }
      button {
        margin: 1rem 0 0.5rem 0;
      }

      .follow-up {
        color: #6c6e88;
        text-align: center;
        margin-top: 0.5rem;
      }
    }

    .subcard:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }

  .bold {
    font-weight: 600;
  }
}
