@use "../../styles/_colors";
@use "../../styles/_media";
@use "../../styles/_spacing";
@use "../../styles/_typography";
@use "../../styles/_z-index";

// Animations
.toast-enter {
  transform: translateY(-100%);

  @include media.breakpoint(tablet) {
    transform: translateX(100%);
  }
}
.toast-enter-active {
  transform: translateY(0%);
  transition: all 500ms;

  @include media.breakpoint(tablet) {
    transform: translateX(0);
  }
}
.toast-exit-active {
  transform: translateY(-100%);
  transition: all 500ms;

  @include media.breakpoint(tablet) {
    transform: translateX(100%);
  }
}

// Styling
.toasts-wrapper {
  position: fixed;
  display: flex;
  align-items: flex-end;

  flex-direction: column-reverse;
  top: spacing.$spacing-90;
  width: 100%;

  pointer-events: none;

  @include media.breakpoint(tablet) {
    flex-direction: column;
    bottom: spacing.$spacing-70;
    right: spacing.$spacing-70;
    top: initial;
    width: fit-content;
    gap: spacing.$spacing-20;
  }
}

$variants: (
    "success": colors.$success,
    "danger": colors.$error,
    "info": colors.$shade-70);

@each $variant, $color in $variants {
  .toast-#{$variant} {
    @extend .h5, .bold;

    z-index: z-index.$toast;

    pointer-events: all;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: spacing.$spacing-30;

    background-color: $color;

    padding: spacing.$spacing-30 spacing.$spacing-60;
    width: 100%;

    @include media.breakpoint(tablet) {
      border-radius: 4px;

      padding: spacing.$spacing-40 spacing.$spacing-60;
      width: fit-content;
    }
  }
}

.toast-message {
  @extend .h5, .bold;
  color: colors.$shade-0;
}

.toast-icon {
  color: colors.$shade-0;
  cursor: pointer;
}
