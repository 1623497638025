.detail-section {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .header {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .loader {
      margin-left: 2rem;
      margin-bottom: 8px;
    }

    .recommendation {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;

      .icon {
        margin-right: 0.3rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .field-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h5 {
      max-width: 50%;
      margin-bottom: 0.5rem;
    }

    h5:first-child {
      text-align: left;
    }
    h5:last-child {
      text-align: right;
    }
  }
}
