.layout {
  margin: 0 auto;
  min-height: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(246, 246, 246, 0.81) 0%, rgba(246, 246, 246, 0) 100%);
  max-width: 800px;
  margin: 0 auto;


  .page {
    padding: calc(65px + 2rem) 1.5rem 2rem 1.5rem;
    box-sizing: border-box;
    min-height: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: #FBFBFC;
    position:relative;
  }

  .alternate-page {
    padding: 65px 0 0 0;
    box-sizing: border-box;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: #FBFBFC;
  }

  .custom-toast {
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    max-width: 800px;
    box-sizing: border-box;
  }
}

.success {
  color: #08a966;
  font-weight: 600;
}

.error {
  color: #ea3f3f;
  font-weight: 600;
}