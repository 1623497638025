@use "../../styles/_colors";
@use "../../styles/_spacing";



.custom-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 65px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: calc(100% - 65px);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .custom-modal {
    max-height: 100%;
    
    
    .modal-wrapper {
      padding: 0;
      margin: 0;
      overflow-y: scroll;
      scrollbar-width: none;
      background-color: colors.$shade-0;
      height: 100%;
      margin: 0 auto;
      max-width: 800px;
      
      
      .modal-header {
        position: fixed;
        width: 100%;
        padding: spacing.$spacing-40 spacing.$spacing-60;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: colors.$shade-0;
        border-bottom: 1px solid colors.$shade-30;
        z-index: 2;
        max-width: 800px;
    
        h4 {
          font-weight: 700;
        }
    
        button {
          background-color: transparent;
          border: none;
        }
      }
    
      .modal-body {
        padding: 0 spacing.$spacing-60;
        margin-top: 53px;

      }
    }
  }
}

// custom styles for camera modal
.camera-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .camera-modal {
    height: 100%;
    background-color: #171718;

    .modal-wrapper {
      padding: 0;
      margin: 0;
      overflow-y: scroll;
      scrollbar-width: none;
      background-color: #171718;
      height: 100%;
      margin: 0 auto;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      
      .modal-header {
        width: 100%;
        padding: spacing.$spacing-40 spacing.$spacing-60;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        border-bottom: 1px solid colors.$shade-80;
        z-index: 3;
        max-width: 800px;
    
        h4 {
          font-weight: 700;
          color: #fff;
          margin: 0 auto;
        }
    
        button {
          background-color: transparent;
          border: none;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        text-align: center;

        .camera-wrapper {
          flex: 1;

          .button-wrapper {
            display: none;
          }
        }

        .content-wrapper { 
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 3rem 1.5rem;
          box-sizing: border-box;
        }
      }
    }
  }
}

/*
Animations
*/

.custom-overlay {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.custom-overlay.ReactModal__Overlay--after-open{
  opacity: 1;
}

.custom-overlay.ReactModal__Overlay--before-close{
  opacity: 0;
}

.custom-modal {
  transform: translateY(100%);
  transition: all 300ms ease-in-out;
}

.custom-modal.ReactModal__Content--after-open{
  transform: translateY(0);
}

.custom-modal.ReactModal__Content--before-close{
  transform: translateY(100%);
}

/*
Camera Modal Animations
*/

.camera-overlay {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.camera-overlay.ReactModal__Overlay--after-open{
  opacity: 1;
}

.camera-overlay.ReactModal__Overlay--before-close{
  opacity: 0;
}

.camera-modal {
  transform: translateY(100%);
  transition: all 300ms ease-in-out;
}

.camera-modal.ReactModal__Content--after-open{
  transform: translateY(0);
}

.camera-modal.ReactModal__Content--before-close{
  transform: translateY(100%);
}