@use 'sass:color';

@use "../../styles/_colors";
@use "../../styles/_spacing";
@use "../../styles/_typography";

$variants: (
    "success": colors.$success,
    "warning": colors.$warning,
    "danger": colors.$error,
    "pending": colors.$shade-70);

@each $variant, $color in $variants {
  .badge-#{$variant} {
    @extend .h6, .bold;

    border-radius: 2px;
    padding: 0 spacing.$spacing-10;
    width: fit-content;

    color: $color;
    background-color: color.change($color, $alpha: 0.1);
  }
}
