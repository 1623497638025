.navbar {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 65px;
  border-bottom: 2px solid #f8fafc;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  top: 0;
  max-width: 800px;

  .arrow {
    transform: rotate(-90deg);
    cursor: pointer;
  }

  .inactive {
    opacity: 0;
    cursor: auto;
  }

  .logo {
    max-width: 150px;
    max-height: 50px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .lang-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}
