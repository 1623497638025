.drawer-wrapper {
  padding: 0;
  margin: 0;
  max-width: 800px;
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0px 16px 40px rgba(37, 39, 82, 0.25);
  border-top: 1px solid #ddd;
  z-index: 2;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  transform: translateY(100%);
  transition: all 300ms ease-in-out;

  &.drawer-wrapper.drawer-open {
    transform: translateY(0);
  }

  .drawer-header {
    // position: fixed;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 2;
    max-width: 800px;
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #ddd;

    h4 {
      font-weight: 700;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }

  .drawer-body {
    padding: 1.5rem;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;

      .heart-lg {
        margin-right: 1rem;
        min-width: 2rem;
      }
    }

    .link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
