.billing-modal {
  padding: 1.5rem 0 3rem 0;
  .card {
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin: 1rem 0;
    padding: 0.75rem 1rem;
  }
  .select-card {
    border-bottom: 1px solid #ddd;

    .row:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.75rem 0;

      p {
        margin-top: -2px;
      }

      .price {
        margin-left: auto;
      }
    }
  }

  .info-card {
    background: #fbfbfc;
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 1rem;
    }
  }

  .select-payment-section {
    padding-bottom: 1rem;
    h4 {
      margin-bottom: 1rem;
    }
  }

  .select-balance-section {
    padding-bottom: 1rem;
  }

  .select-card {
    border: 1px solid #ddd;
    border-radius: 4px;

    .select-row.payment-plan {
      h4 {
        line-height: 20px;
      }
    }

    .select-row {
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      display: flex;
      width: 100%;
      box-sizing: border-box;
      align-items: center;

      img {
        margin-right: 0.5rem;
      }

      label {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;

        h4 {
          margin-bottom: 0;
        }

        .date {
          margin-left: auto;
        }
      }

      input {
        margin: 0 4px 0 0;
        width: 20px;
        height: 20px;
      }
    }
    .expanded {
      background-color: #fbfbfc;
      color: #6c6e88;
      text-align: center;
      padding: 1.5rem;
      border-bottom: 1px solid #ddd;

      img {
        margin-bottom: 0.5rem;
      }
    }
  }

  .billing-card {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 1rem 0 0 0;

    h4 {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .payment-plan-section {
    padding-bottom: 1rem;

    .card {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      margin: 1.5rem auto 1rem auto;
    }

    .selection-card {
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-bottom: 1rem;

      .recommendation-label {
        background-color: rgba(56, 114, 212, 0.1);
        display: inline-block;
        transform: translateY(-3px);
        padding: 2px 4px;
        border-radius: 2px;
        margin-bottom: 0.5rem;
        font-size: 0.75rem;
      }

      .selection {
        padding: 1rem 1rem 0 1rem;
        display: flex;
      }
      .selection:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      .input-col {
        margin-right: 1rem;
      }

      .content {
        width: 100%;
        .payment-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .payment {
            font-size: 1rem;
            font-weight: 600;
          }
          .duration {
            font-size: 0.75rem;
            color: #333;
          }
          .duration-description {
            font-weight: 600;
            font-size: 0.75rem;
            // color: #3872d4;
          }

          .see-more {
            display: flex;
            gap: 4px;
            align-items: center;
          }
        }

        .installment-widget {
          margin-left: 0;
          padding-left: 0;
          .bar {
            list-style: none;
            margin: 0.75rem 0;
            padding-left: 0;
          }
          .bar > li {
            position: relative;
            min-height: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;

            span {
              margin-left: auto;
            }
          }
          .bar > li:before {
            content: "\25CF";
            margin-right: 10px;
            font-size: 20px;
            color: #ddd;
            border-radius: 100%;
          }
          .bar > li:after {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            border-left: 2px dotted #ddd;
            margin-left: 8px;
            height: 100%;
            margin-top: -2px;
          }
          .bar > li:first-of-type:after {
            top: 50%;
          }
          .bar > li:last-of-type:after {
            top: -50%;
          }
        }

        .label {
          font-size: 12px;
          font-weight: 600;
          color: #6c6e88;
        }
      }
    }

    .rate-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin: 1rem 0;
    }
  }

  .confirm-total-section {
    padding-bottom: 1rem;

    .total-card {
      flex-direction: column;
      .header-row {
        display: flex;
        align-items: center;
      }

      .rate-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 1rem 0;
      }

      .payment-item-row {
        margin-top: 0.75rem;
        display: flex;
        width: 100%;

        .circle {
          border: 2px solid #ddd;
          width: 10px;
          height: 10px;
          margin-right: 1rem;
          border-radius: 50%;
          margin-top: 2px;
        }

        .circle.closed {
          background-color: #ddd;
        }
        .price {
          margin-left: auto;
          font-weight: 600;
        }
      }
    }

    .title {
      margin-bottom: 1rem;
    }
    .card {
      display: flex;
      justify-content: space-between;
    }

    .info-card {
      margin: 1rem 0 2rem;
    }

    .disclaimer {
      margin: 0 0 2rem 0;
    }

    .b-contain {
      margin-bottom: 1rem;
      p {
        margin-top: -5px;

        .underline {
          text-decoration: underline;
          font-weight: 600;
          color: #6c6e88;
        }
      }
    }
  }
}
