.auth-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .intro-section {
    background-color: white;
    padding: 2rem 1.5rem;
    box-sizing: border-box;
  }

  .form-section {
    padding: 1.5rem;

    .form-group {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      label h4 {
        padding-bottom: 0.75rem;
      }

      input {
        font-family: "inter";
      }

      input:not([type="checkbox"]) {
        border: 1px solid #c4c4c4;
        width: 100%;
        font-size: 16px;
        color: black;
        box-sizing: border-box;
        height: 38px;
        background-color: white;
        border-radius: 4px;
      }

      input:focus {
        border: 1px solid #333;
      }

      // input[type="date"] {
      //       -webkit-appearance: textfield;
      //       -moz-appearance: textfield;
      //       min-height: 1.2em;
      //   }

      input[type="checkbox"] {
        appearance: none;
      }

      button {
        cursor: pointer;
      }
    }
  }

  .footer-section {
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    .help-link {
      margin-bottom: 1.5rem;
    }

    .disclaimer {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .logo-subsection {
      opacity: 0.5;
      margin-top: auto;
      margin-bottom: calc(48px - 2rem);
    }
  }
}

.phone-num-verification {
  padding: 2rem 0 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;

  h4:first-child {
    padding-bottom: 0.75rem;
  }

  .loader {
    margin-top: 0.75rem;
  }

  .error {
    margin-top: 0.5rem;
  }

  .resend-link {
    cursor: pointer;
    margin-top: 1.5rem;
    text-align: center;
  }

  .container {
    margin: 0 auto;
  }

  .character {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
  }

  .character-selected {
    background-color: #fff;
  }

  .character--inactive {
    background-color: #fff;
  }
}

.layout .alternate-page {
  background: linear-gradient(
    180deg,
    rgba(241, 241, 241, 0.81) 0%,
    rgba(246, 246, 246, 0) 100%
  ) !important;
}
