.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  outline: none !important;
}

.b-contain input:hover {
  outline: none !important;
}

.b-contain input:focus {
  outline: none !important;
}

.b-contain input:active {
  outline: none !important;
}

.b-contain span {
  font-size: 1rem;
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 1.75rem;
  cursor: pointer;
}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 2px;
  outline: none !important;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #f1f5f9;
  transition: background 250ms;
  border: 1px solid #94a3b8;
  border-radius: 3rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 1px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 3rem;
  background: #ffffff;
  transition: background 250ms;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input::after {
  border-color: #ffffff;
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled]) ~ .b-input,
.b-contain input[type="checkbox"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain:hover input[type="radio"]:not([disabled]) ~ .b-input,
.b-contain input[type="radio"]:focus ~ .b-input {
  background: #e2e8f0;
  border-color: #64748b;
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px #60a5fa;
}

.b-contain input[type="checkbox"]:checked ~ .b-input {
  background: #0b67fd;
  border-color: #0b67fd;
}

.b-contain input[type="radio"]:checked ~ .b-input {
  background: #3b82f6;
  border-color: #1d4ed8;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input,
.b-contain input[type="radio"]:disabled ~ .b-input {
  opacity: 0.5;
  cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input::after {
  background: #ffffff;
}

.b-contain:hover input[type="checkbox"]:not([disabled]):checked ~ .b-input,
.b-contain input[type="checkbox"]:checked:focus ~ .b-input {
  background: #0b67fd;
  border-color: #0b67fd;
}

.b-contain:hover input[type="radio"]:not([disabled]):checked ~ .b-input,
.b-contain input[type="radio"]:checked:focus ~ .b-input {
  background: #2563eb;
  border-color: #1e40af;
}
