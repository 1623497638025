@use "../../styles/_colors";
@use "../../styles/_spacing";

.confirm-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;

  .confirm-modal {
    align-items: center;
    background-color: colors.$shade-0;
    box-shadow: 0px 0px 16px rgba(37, 39, 82, 0.05);
    border: 1px solid colors.$shade-40;
    margin: 0 auto;
    border-radius: 4px;
    width: 100%;
    margin: 0 1.5rem;

    .confirm-wrapper {
      padding: spacing.$spacing-60;
      text-align: center;

      svg {
        margin: spacing.$spacing-20 auto;
        height: 2rem;
        width: 2rem;
        overflow: visible;
      }

      h4 {
        font-weight: 600;
        margin: spacing.$spacing-20 0;
      }

      p {
        margin-bottom: 0.5rem;
      }

      button {
        margin: spacing.$spacing-20 0 0.5rem 0;
      }
    }
  }
}

/*
Animations
*/

.confirm-overlay {
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.confirm-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}

.confirm-overlay.ReactModal__Overlay--before-close {
  opacity: 0;
}

.confirm-modal {
  transform: translateY(20px);
  transition: all 300ms ease-in-out;
}

.confirm-modal.ReactModal__Content--after-open {
  transform: translateY(0);
}

.confirm-modal.ReactModal__Content--before-close {
  transform: translateY(20px);
}
