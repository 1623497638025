.card-default {
  color: '#333333';
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: .75rem;
  margin: 1rem 0;

  .image-card {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  img {margin-right: 1rem;}
}