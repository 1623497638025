@use "_colors";
@use "_spacing";
@use "_typography";


.appointment-card {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-40;
  box-sizing: border-box;

  background-color: colors.$shade-0;

  border: 1px solid colors.$shade-40;
  border-radius: 4px;

  padding: spacing.$spacing-50 spacing.$spacing-40;

  width: 100%;

  & .header {
    display: flex;
    flex-direction: column;
    gap: spacing.$spacing-20;

    .badges {
      display: flex;
      flex-direction: row;
      
      div:not(:last-child) {
        margin-right: spacing.$spacing-20;
      }
    }

    & .title-wrapper {
      display: flex;
      gap: spacing.$spacing-20;

      & > span {
        @extend .h4;
      }

      & .arrival-time {
        color: colors.$shade-70;
      }
    }
  }
}
