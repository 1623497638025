$primary: #0b67fd;
$primary-dark: #0b5ed7;

$secondary: #0f1b5d;
$secondary-light: #1f2e86;

$tertiary: #dbecff;

$pcma: #3872d4;

$shade-0: #ffffff;
$shade-10: #fbfbfc;
$shade-20: #f6f6f6;
$shade-30: #eeeeee;
$shade-40: #dddddd;
$shade-50: #c4c4c4;
$shade-60: #8e8e8e;
$shade-70: #6c6e88;
$shade-80: #333333;
$shade-90: #171718;
$shade-100: #000000;

$success: #08a966;
$warning: #fab247;
$error: #ea3f3f;
