@use './_colors';

.h1 {
  color: colors.$shade-80;
  font-size: 2rem;
  line-height: 2.25rem;
}

h1 {
  @extend .h1;
}

.h2 {
  color: colors.$shade-80;
  font-size: 1.5rem;
  line-height: 1.875rem;
}

h2 {
  @extend .h2;
}

.h3 {
  color: colors.$shade-80;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

h3 {
  @extend .h3;
}

.h4 {
  color: colors.$shade-80;
  font-size: 1rem;
  line-height: 1.25rem;
}

h4 {
  @extend .h4;
}

.h5 {
  color: colors.$shade-80;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

h5 {
  @extend .h5;
}

.h6 {
  color: colors.$shade-80;
  font-size: 0.75rem;
  line-height: 1rem;
}

h6 {
  @extend .h6;
}

.p1 {
  color: colors.$shade-80;
  font-size: 1.125rem;
  line-height: 2rem;
}

.p2 {
  color: colors.$shade-80;
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  @extend .p2;
}

.p3 {
  color: colors.$shade-80;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.bold {
  font-weight: 600;
}

// TODO: Link styling
